import React, {useState} from "react";
import axios from "axios";
import { auth } from '../../utils/firebase';
import { useAuthState } from "react-firebase-hooks/auth";

import CircularProgress from '@mui/material/CircularProgress';

import settings from '../../constants/settings.json'

export function ShipButton(channel) {

    const url=settings.URL

    const [loading, setLoading] = useState(false);
    const [user, load, error] = useAuthState(auth);

    var token = null;
    user.getIdToken().then((result) => {
        token = result;
    });

    const Ship = async ({channel}) => {
        setLoading(true);
        try {
            await axios({
                method: "get",
                url: `${url}/${channel}/shipment`,
                headers: { 
                    'authorization': token,
                },
            })
            .then(res => {
                console.log(res.data);
                alert("The orders were processed succesfully.")
            });
        } catch (e){
            console.log(e);
            alert("An error occured. Please contact support.")
        }
        setLoading(false);  
    }

    return (
            <div>
                <div>
                    {loading ? <CircularProgress /> : null}
                </div>
                <button onClick={() => Ship(channel)}>
                    Prepare Shipments
                </button>
            </div>
    )
}