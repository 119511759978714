import React from "react";
import { AcknowledgeButton } from "./Acknowledge";
import { GenerateBOLsButton } from "./Generate BOLs/GenerateBOLs";
import { ShipButton } from "./Ship";

//styling
import './Actions.css'

export function Actions({channel, orders}){

  const anyOrders = orders.length>0;
  console.log(channel);
  console.log(orders);

  return (
    <div id="actions" className='actions'>
      {(channel=='grocery')&anyOrders? <AcknowledgeButton channel={channel} /> : null}
      {anyOrders? <ShipButton channel={channel}/> : null}
      {anyOrders? <GenerateBOLsButton orders={orders} /> : null}
    </div>
  );
}
  