import React from "react";
import './Item.css'

function Item({item_data}) {
  return (
    <div className='item'>
      <div className="item--product--id">#{item_data.Product_Id}</div>
      <div className="item--product--name">{item_data.Product_Name}</div>
      <div className="item--product--quant">x {item_data.Quantity}</div>
    </div>
  );
}

export default Item;