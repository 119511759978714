import React from 'react';
import Item from '../Item/Item';
import './Order.css'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

export function Order({order_data}) {
  
  const [open, setOpen] = React.useState(true);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
  <div className='order'>
    <div className='order_card'>
      <div className='order--card--arrow' onClick={handleOpen}>
        {open ?  
        <ArrowForwardIosRoundedIcon 
          fontSize="large"
          sx={{transform: 'rotate(90deg)'}}
        /> :         
        <ArrowForwardIosRoundedIcon 
          fontSize="large" 
        /> }
      </div>
      <div className='order--data--store'>
        <div>{order_data.Store}</div>
        <div>{order_data.Store_Address}</div>
      </div>
      <div>
        <div className='order--data--sku--title'>Skus</div>
        <div className='order--data--sku'>{order_data.Items.length}</div>
      </div>
      <div>
        <div className='order--data--delivery--title'>Delivery day</div>
        <div className='order--data--delivery'>{order_data.Delivery_date}</div>
      </div>
    </div>
    { open ? 
      <div className='listItems'>
        {order_data.Items.map(item => (
          <div className='details'>
            <Item 
              item_data={item}
              key={item.Product_Id}
            />
          </div>
        ))}
      </div>
    : null
    }
  </div>
  );
}
  