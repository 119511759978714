import React, {useState} from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import { styles } from "./BillOfLadding.style";

import logo from "../../../assets/logo.png";

export const BillOfLadding = ({orders}) => (
        <Document>
          {orders.map((order, index) => {
            let items_number = order.Items.reduce((a, b) => a + b.Quantity, 0);
            return (
              <Page size="A4" style={styles.page} key={index}>
                <View style={styles.section}>
                  <Text style={styles.heading}>Bill Of Ladding</Text>
                </View>
                <View style={[styles.rowcontainer, styles.section]}>
                  <View style={styles.half}>
                    <Text>PO# {order["PO#"]}</Text>
                    <Text style={{ paddingBottom: 5 }}>
                      Probill# {order["Probill"]}
                    </Text>
                    <Text>Delivery Date: {order["Delivery_date"]}</Text>
                    <Text style={{ paddingBottom: 5 }}>Carrier: TNG</Text>
                    <Text>Pre-paid Freight</Text>
                  </View>
                  <View style={styles.half}>
                    <View style={styles.columncontainer}>
                      <View style={styles.addressBox}>
                        <Text
                          style={{
                            fontFamily: "Helvetica-Bold",
                            fontSize: "13",
                            paddingBottom: 5,
                          }}
                        >
                          Ship To
                        </Text>
                        <Text
                          style={{
                            fontSize: "17",
                            fontWeight: "100",
                            paddingBottom: 5,
                          }}
                        >
                          {order['BOL#']}
                        </Text>
                        <Text>{order.Store}</Text>
                        <Text>
                          {order.Store_Address.split(" ").slice(0, -1).join(" ")}
                        </Text>
                        <Text>{order.Store_Address.split(" ").slice(-1)}</Text>
                      </View>
      
                      <View
                        style={[styles.addressBox, { backgroundColor: "#EFF3F8" }]}
                      >
                        <Text
                          style={{
                            fontFamily: "Helvetica-Bold",
                            fontSize: "13",
                            paddingBottom: 5,
                          }}
                        >
                          From
                        </Text>
                        <Text>Equals Brewing Inc</Text>
                        <Text>695 Sovereign Rd</Text>
                        <Text>London, ON N5V 4K8</Text>
                        <Text>519-432-1180</Text>
                        <Text>dave.martin@equalsbrewing.com</Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={[
                    styles.columncontainer,
                    styles.section,
                    { paddingBottom: 10 },
                  ]}
                >
                  <Text style={{ fontSize: 15, paddingBottom: 10 }}>Items</Text>
                  {order.Items.map((item, index) => {
                    if (index % 2 == 0) {
                      return (
                        <View style={styles.orderBox} key={index}>
                          <View style={styles.rowcontainer}>
                            <Text style={{ paddingRight: 10, fontWeight: 900 }}>
                              #{item.Product_Id}
                            </Text>
                            <Text>{item.Product_Name}</Text>
                          </View>
                          <Text>x{item.Quantity}</Text>
                        </View>
                      );
                    } else {
                      return (
                        <View
                          style={[styles.orderBox, { backgroundColor: "#EFF3F8" }]}
                          key={index}
                        >
                          <View style={styles.rowcontainer}>
                            <Text style={{ paddingRight: 10, fontWeight: 900 }}>
                              #{item.Product_Id}
                            </Text>
                            <Text>{item.Product_Name}</Text>
                          </View>
                          <Text>x{item.Quantity_Case}</Text>
                        </View>
                      );
                    }
                  })}
      
                  <View
                    style={[
                      styles.rowcontainer,
                      { justifyContent: "flex-end", marginTop: 100, fontSize: 15 },
                    ]}
                  >
                    <View style={[styles.columncontainer, { marginRight: 20 }]}>
                      <Text>SKUs</Text>
                      <Text>x{order.Items.length}</Text>
                    </View>
                    <View style={styles.columncontainer}>
                      <Text>Items</Text>
                      <Text>x{items_number}</Text>
                    </View>
                  </View>
                </View>
      
                <View
                  style={[
                    styles.rowcontainer,
                    { justifyContent: "space-between", marginBottom: 10 },
                  ]}
                >
                  <Text style={{ fontSize: 15 }}>Delivery</Text>
                  <View style={styles.rowcontainer}>
                    <Text>Date:</Text>
                    <View style={styles.underline}></View>
                  </View>
                </View>
                <View style={styles.rowcontainer}>
                  <View
                    style={[
                      styles.addressBox,
                      {
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        height: 150,
                        flex: "0.5",
                        marginRight: 20,
                      },
                    ]}
                  >
                    <View style={styles.rowcontainer}>
                      <Text>Delivered By:</Text>
                      <View style={styles.underline}></View>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.addressBox,
                      {
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        height: 150,
                        flex: "0.5",
                      },
                    ]}
                  >
                    <View style={styles.rowcontainer}>
                      <Text>Received By:</Text>
                      <View style={styles.underline}></View>
                    </View>
                  </View>
                </View>
                <View style={[styles.rowcontainer, { alignItems: "center" }]}>
                  <Image
                    src={logo}
                    style={{ width: 30, height: 30, marginRight: 20 }}
                  ></Image>
                  <Text>Generated with shipmental.ca</Text>
                </View>
              </Page>
            );
          })}
        </Document>
    );