import React, {useState, useEffect} from "react";
import {Order} from './Order/Order'

export function DisplayOrders({orders}) {
  
  const anyOrders = orders.length>0;

  return (
    <div>
      {
        anyOrders?
          orders.map(
          order => <Order order_data={order} />
        )
        : <div>No New Orders.</div>
      }
    </div>
  )
}
;
  