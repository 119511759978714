// Create styles
import { StyleSheet } from "@react-pdf/renderer";
export const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
  },
  section: {
    borderBottom: "2px solid black",
    marginBottom: 20,
  },
  heading: {
    fontWeight: "bold",
    textAlign: "center",
    paddingBottom: 20,
    fontFamily: "Helvetica-Bold",
    fontSize: 25,
  },
  rowcontainer: {
    display: "flex",
    flexDirection: "row",
  },
  half: {
    flex: 0.5,
  },
  columncontainer: {
    display: "flex",
    flexDirection: "column",
  },
  addressBox: {
    backgroundColor: "#FAFAFA",
    marginBottom: 10,
    paddingVertical: 10,
    paddingHorizontal: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  orderBox: {
    width: "100%",
    backgroundColor: "#FAFAFA",
    padding: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  underline: {
    width: 150,
    height: 12,
    borderBottom: "1px solid black",
  },
});
