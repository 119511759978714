import React, {useState} from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { styles } from "./BillOfLadding.style";

import { BillOfLadding } from "./BillOfLadding";

import logo from "../../../assets/logo.png";
import CircularProgress from '@mui/material/CircularProgress';

export function GenerateBOLsButton({orders}) {

    const [loading, setLoading] = useState(false);

    const GenerateBOLs = async ({orders}) => {
        setLoading(true);
        try {
         
        } catch (e){
            console.log(e);
        }
        setLoading(false);  
    }

    return (
            <div>
                <div>
                    {loading ? <CircularProgress /> : null}
                </div>
                <PDFDownloadLink
                    document={<BillOfLadding orders={orders} />}
                    fileName="BOL.pdf"
                    style={{ textDecoration: "none" }}
                >
                <button>Download BOLs</button>
                </PDFDownloadLink>

            </div>
    )
}