// Packages
import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { auth } from '../../utils/firebase';
import { useAuthState } from "react-firebase-hooks/auth";

// Components
import { DisplayOrders } from '../../components/DisplayOrders/DisplayOrders';
import FetchOrders from '../../components/FetchOrders/FetchOrders';
import { Actions } from "../../components/ProcessOrders/Actions";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

// Styling
import './Home.css'

// Data
import settings from '../../constants/settings.json'

function Home() {

  // Get settings data
  const CHANNEL = settings.CHANNEL;

  // Iniitialize channel
  const [channel, setChannel] = React.useState(0);

  // Initialize localStorage
  if (!localStorage.getItem(CHANNEL[channel].endpoint)) {
    localStorage.setItem(CHANNEL[channel].endpoint, JSON.stringify({ "Orders": [] }));
  }

  // Handle channel switch
  const handleChange = (event) => {
    setChannel(event.target.value);
    setOpen(false);
    if (!localStorage.getItem(CHANNEL[event.target.value].endpoint)) {
      localStorage.setItem(CHANNEL[event.target.value].endpoint, JSON.stringify({ "Orders": [] }));
    }
    setData(localStorage.getItem(CHANNEL[event.target.value].endpoint));
  };

  // Get orders orders to be displayed
  const [data, setData] = React.useState(localStorage.getItem(CHANNEL[channel].endpoint));
  //Update orders when data changes
  useEffect(() => {
    window.addEventListener('storage', () => {
      setData(localStorage.getItem(CHANNEL[channel].endpoint));
    });
  });

  // Channel selector state: open/close
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };

  // const token = sessionStorage.getItem("token");
  const [user, loading, error] = useAuthState(auth);

  if (!user) {
    return <Navigate replace to="/login" />;
  } else {
    return (
      <div className="wrapper">
        <div id='top' className='top'>
          <div id='channelSelector' className="channelSelector">
            <div 
              className='channel_title'
              onClick={handleOpen}
            >
              <h1>{CHANNEL[channel].label}</h1>
              <ArrowForwardIosRoundedIcon
                fontSize='large'
                sx={{ transform: "rotate(90deg)" }}
              />
            </div>
            <div id='channelDropdown' className="channelDropdown" >
              {open ? 
                <div>
                  {CHANNEL.map(channel => (
                    <button
                      value={channel.key}
                      onClick={handleChange}
                      key={channel.key}
                    className="channel--dropdown--button">
                      {channel.label}
                    </button>
                  ))}
                </div>
                : null
              }
            </div>
          </div>
          <FetchOrders channel={CHANNEL[channel].endpoint} />
        </div>

        <DisplayOrders orders={JSON.parse(data).Orders} />
        <Actions channel={CHANNEL[channel].endpoint} orders={JSON.parse(data).Orders} />
      </div>
    );
  }
}

export default Home;