import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword } from '../../utils/firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

import './Auth.css'

const Login = (props) => {

    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [user, loading, error] = useAuthState(auth);

    useEffect(() => {
        if (loading) {
            // maybe trigger a loading screen
            return;
        }
        if (user) navigate("/");
    }, [user, loading]);

    const togglePassword = (e) => {
          setShowPassword(!showPassword);
      };

    return (
        <div className='form'>
            <div className='sub-form'>
                <div>
                    <h1>
                        Let's Ship
                    </h1>
                    <div className='login-info'>
                        <div className='form-field'>
                            <input
                                type="email"
                                value={email}
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                        
                            />
                        </div>
                        <div className='form-field'>
                            <input
                                type={showPassword ? "text" : "password"}
                                value={password}
                                placeholder="Password"
                                onChange={(e) => setPassword(e.target.value)}
                        
                            />
                            <div className='visibilityToggle'
                                onClick={(e) => togglePassword(e)}
                            >
                                {showPassword? <VisibilityIcon/>
                                : <VisibilityOffIcon/>}
                            </div>
                        </div>
                    </div>
                    <div className='login-button'>
                        <button
                            type="button"
                            onClick={()=>logInWithEmailAndPassword(email, password)}
                        >
                            Log In
                        </button>
                    </div>
                </div>
            </div>  
            <p className='made--note'>Shipmental by Mutoal Future Inc.</p>
            <div className='footer--note'>
                <p>Est. 2022</p>
            </div>      
        </div>
    )
}

export default Login;