import React, {useState} from 'react';
import axios from "axios";
import { auth } from '../../utils/firebase';
import { useAuthState } from "react-firebase-hooks/auth";

import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
import CircularProgress from '@mui/material/CircularProgress';

import settings from '../../constants/settings.json'

//styling
import './FetchOrders.css'

function FetchOrders(channel) {

    const url=settings.URL

    const [loading, setLoading] = useState(false);
    const [user, load, error] = useAuthState(auth);
    
    var token = null;
    user.getIdToken().then((result) => {
        token = result;
    });

    const getOrders = async ({channel}) => {
        console.log(token);
        setLoading(true);
        try {
            await axios({
                method: "get",
                url: `${url}/${channel}/new_orders`,
                headers: { 
                    'authorization': token,
                },
            }).then(res => {
                console.log(res.data);
                localStorage.setItem(channel, JSON.stringify(res.data));
                window.dispatchEvent(new Event('storage'))
                alert("The orders were fetched successfully.")
            });
        } catch (e){
            console.log(e);
            alert("An error occured. Please contact support.")
        }
        setLoading(false);

    };

    return(
        <div className={'fetchNewOrders'} >
            <div className='fetchDiv'>
                Fetch New Orders
                {loading ? <CircularProgress className='loadIcon' /> : null}
            </div>
            <button className='fetchButton' onClick={() => getOrders(channel)}>
                <SyncRoundedIcon className='syncIcon' />
            </button>
        </div>
    )
};

export default FetchOrders;

